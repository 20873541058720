import type { Entry, Entries } from '~/statamic/types/entry';
import { entryQuery, entriesQuery } from '~/statamic/queries/entry';

type EntryOptions = {
  id?: string;
  uri?: string;
  site?: string;
  slug?: string;
  filter?: {[key: string]: any};
  status?: {
    in: string;
  }[];
  preview?: boolean;
  collection?: string;
};

type EntriesOptions = {
  sort?: string;
  site?: string;
  page?: number;
  limit?: number;
  filter?: {[key: string]: any};
  collection?: string;
};

export const useStatamicEntry = async (options: EntryOptions, allowToFail: boolean = true): Promise<Entry | undefined> => {
  const { query } = useRoute();
  const { $statamic } = useNuxtApp();

  if (!options.site) {
    options.site = $statamic.site.handle;
  }

  if (!options.filter) {
    options.filter = {};
  }

  options.filter.status = [{
    in: (typeof query['live-preview'] !== 'undefined') ? 'draft|published' : 'published',
  }];

  const { data } = await useAsyncQuery<{entry: Entry}>(entryQuery, options);
  const entry = data?.value?.entry;

  if (!entry && allowToFail) {
    throw createError({ statusCode: 404, statusMessage: `Page not found... (${options.uri})` });
  }

  return entry;
};

export const useStatamicEntries = async (options: EntriesOptions): Promise<Entries> => {
  const { $statamic } = useNuxtApp();

  if (!options.site) {
    options.site = $statamic.site.handle;
  }

  const { data } = await useAsyncQuery<{entries: Entries}>(entriesQuery, options);
  const entries = data?.value?.entries;

  if (!entries) {
    throw new Error('Missing entries from Statamic.');
  }

  return entries;
};
