const headerState = reactive<{
  isLight?: boolean;
}>({
  isLight: false,
});

export const useHeader = () => {
  function changeHeaderMode(isLight: boolean) {
    headerState.isLight = isLight;
  }

  return {
    headerState,
    changeHeaderMode,
  };
};
