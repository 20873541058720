import { seoQuery } from './seo';
import { blocksQuery, blocksFragment } from './block';

export const entryQuery = gql`
  ${blocksFragment}

  query getEntry(
    $id: String,
    $uri: String,
    $slug: String,
    $site: String!,
    $filter: JsonArgument,
    $collection: String,
  ) {
    entry(
      id: $id,
      uri: $uri,
      slug: $slug,
      site: $site,
      filter: $filter,
      collection: $collection,
    ) {
      id
      url
      date
      title
      status
      permalink
      collection { handle }

      ... on Entry_Pages_Page {
        ${seoQuery}
        ${blocksQuery}
      }

      ... on Entry_Projects_Project {
        ${seoQuery}
        ${blocksQuery}

        company
        image {
          id
          md: responsiveAsset(width: 960, height: 422, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1280, height: 720, format: "webp") { url, alt, width, height }
          xl: responsiveAsset(width: 2560, height: 1440, format: "webp") { url, alt, width, height }
        }
        project_tags {
          id
          title
        }
      }

      ... on Entry_Articles_Article {
        ${seoQuery}
        ${blocksQuery}

        image {
          id
          md: responsiveAsset(width: 960, height: 422, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1280, height: 720, format: "webp") { url, alt, width, height }
        }
        author {
          ... on Entry_Employees_Employee {
            url
            title
            position
            image {
              id
              sm: responsiveAsset(width: 108, height: 108, format: "webp") { url, alt, width, height }
            }
          }
        }
        breadcrumbs(showHome: true) {
          url
          title
        }
        reading_time
      }

      ... on Entry_Courses_Course {
        ${seoQuery}
        ${blocksQuery}

        text
        price
        location
        course_dates {
          id
          course_dates_date
        }
        image {
          id
          md: responsiveAsset(width: 960, height: 960, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1280, height: 1280, format: "webp") { url, alt, width, height }
          xl: responsiveAsset(width: 1920, height: 1920, format: "webp") { url, alt, width, height }
        }
        breadcrumbs(showHome: false) {
          url
          title
        }
        course_categories {
          id
          title
        }
        employee {
          ... on Entry_Employees_Employee {
            id
            title
            email
            position
            image {
              id
              sm: responsiveAsset(width: 280, height: 280, format: "webp") { url, alt, width, height }
            }
          }
        }
      }

      ... on Entry_Vacancies_Vacancy {
        ${seoQuery}
        ${blocksQuery}

        text
        location
        hours_per_week
        employment_type { value }
        image {
          id
          md: responsiveAsset(width: 960, height: 960, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1280, height: 1280, format: "webp") { url, alt, width, height }
          xl: responsiveAsset(width: 1920, height: 1920, format: "webp") { url, alt, width, height }
        }
        vacancy_categories {
          id
          title
        }
      }

      ... on Entry_Services_Service {
        ${seoQuery}
        ${blocksQuery}

        breadcrumbs(showHome: false) {
          url
          title
        }
      }

      ... on Entry_LandingPages_LandingPage {
        ${seoQuery}
        ${blocksQuery}
      }

      ... on Entry_Employees_Employee {
        ${seoQuery}
        ${blocksQuery}

        url
        text
        email
        title
        position
        linkedin
        image {
          id
          md: responsiveAsset(width: 960, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1280, format: "webp") { url, alt, width, height }
          xl: responsiveAsset(width: 1920, format: "webp") { url, alt, width, height }
        }
      }
    }
  }
`;

export const entriesQuery = gql`
  query getEntries(
    $sort: String = "date desc",
    $site: String!,
    $page: Int! = 0,
    $limit: Int! = 999,
    $filter: JsonArgument,
    $collection: [String],
  ) {
    entries(
      sort: [$sort],
      site: $site,
      page: $page,
      limit: $limit,
      filter: $filter,
      collection: $collection,
    ) {
      to
      from
      total
      last_page
      current_page
      has_more_pages

      data {
        id
        url
        date
        title
        status
        permalink

        ... on Entry_Employees_Employee {
          id
          url
          title
          position
          image {
            id
            xs: responsiveAsset(width: 320, format: "webp") { url, alt, width, height }
            sm: responsiveAsset(width: 640, format: "webp") { url, alt, width, height }
            md: responsiveAsset(width: 960, format: "webp") { url, alt, width, height }
          }
        }
      }
    }
  }
`;
