const defaultBlockFields = `
  id
  type
  background_color { value }
`;

const buttonFields = `
  buttons {
    external
    button_text
    button_link
    button_variant { value }
  }
`;

export const blocksFragment = `
  fragment Hero on Set_Blocks_Hero {
    ${defaultBlockFields}
    ${buttonFields}
    title
    text
    title_color { value }
    type_of_content { value }
    projects {
      ... on Entry_Projects_Project {
        id
        url
        title
        company
        image {
          id
          sm: responsiveAsset(width: 380, height: 420, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 640, height: 700, format: "webp") { url, alt, width, height }
        }
        award_logo {
          xs: responsiveAsset(width: 100, height: 100, format: "webp") { url, alt, width, height }
        }
        project_tags {
          id
          title
        }
      }
    }
    images {
      id
      sm: responsiveAsset(width: 380, height: 420, format: "webp") { url, alt, width, height }
      md: responsiveAsset(width: 640, height: 700, format: "webp") { url, alt, width, height }
    }
  }

  fragment HeroPage on Set_Blocks_HeroPage {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
    text
    image {
      id
      md: responsiveAsset(width: 600, height: 600, format: "webp") { url, alt, width, height }
      lg: responsiveAsset(width: 1200, height: 1200, format: "webp") { url, alt, width, height }
    }
  }

  fragment HeroService on Set_Blocks_HeroService {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
    text
    image {
      id
      md: responsiveAsset(width: 420, height: 340, format: "webp") { url, alt, width, height }
      lg: responsiveAsset(width: 640, height: 512, format: "webp") { url, alt, width, height }
      xl: responsiveAsset(width: 960, height: 820, format: "webp") { url, alt, width, height }
    }
  }

  fragment HeroLandingPage on Set_Blocks_HeroLandingPage {
    ${defaultBlockFields}
    text
    title
    usp_list: list
    logos {
      id
      xs: responsiveAsset(width: 200, format: "webp") { url, alt, width, height }
    }
    employee {
      ... on Entry_Employees_Employee {
        id
        image {
          id
          sm: responsiveAsset(width: 312, height: 312, format: "webp") { url, alt, width, height }
        }
      }
    }
    title_call_to_action
    list_call_to_action
    buttons_call_to_action {
      external
      button_text
      button_link
      button_variant { value }
    }
  }

  fragment HeroProject on Set_Blocks_HeroProject {
    ${defaultBlockFields}
  }

  fragment HeroArticle on Set_Blocks_HeroArticle {
    ${defaultBlockFields}
  }

  fragment HeroCourse on Set_Blocks_HeroCourse {
    ${defaultBlockFields}
  }

  fragment HeroVacancy on Set_Blocks_HeroVacancy {
    ${defaultBlockFields}
  }

  fragment HeroEmployee on Set_Blocks_HeroEmployee {
    ${defaultBlockFields}
  }

  fragment TextImages on Set_Blocks_TextImages {
    ${defaultBlockFields}
    ${buttonFields}
    title
    text
    title_color { value }
    images {
      id
      xs: responsiveAsset(width: 480, format: "webp") { url, alt, width, height }
    }
  }

  fragment Accordion on Set_Blocks_Accordion {
    ${defaultBlockFields}
    ${buttonFields}
    title
    title_color { value }
    accordion_items {
      accordion_item_title
      accordion_item_text
      accordion_item_image {
        sm: responsiveAsset(width: 380, height: 420, format: "webp") { url, alt, width, height }
        md: responsiveAsset(width: 500, height: 540, format: "webp") { url, alt, width, height }
        lg: responsiveAsset(width: 800, height: 864, format: "webp") { url, alt, width, height }
      }
    }
  }

  fragment Video on Set_Blocks_Video {
    ${defaultBlockFields}
    is_full_width
    is_contained
    vimeo_url_uhd_2k
    vimeo_url_hd_1080p
    vimeo_url_sd_540p
  }

  fragment TitleEntries on Set_Blocks_TitleEntries {
    ${defaultBlockFields}
    title_color { value }
    title_small
    title
    type_of_content { value }
    pages {
      ... on Entry_Pages_Page {
        id
        url
        title
        image {
          id
          sm: responsiveAsset(width: 400, height: 240, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 640, height: 385, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1024, height: 616, format: "webp") { url, alt, width, height }
        }
      }
    }
    articles {
      ... on Entry_Articles_Article {
        id
        url
        title
        image {
          id
          sm: responsiveAsset(width: 400, height: 240, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 600, height: 360, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 800, height: 480, format: "webp") { url, alt, width, height }
        }
      }
    }
    projects {
      ... on Entry_Projects_Project {
        id
        url
        title
        image {
          id
          sm: responsiveAsset(width: 400, height: 440, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 700, height: 740, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 1000, height: 1040, format: "webp") { url, alt, width, height }
        }
        award_logo {
          id
          xs: responsiveAsset(width: 100, height: 100, format: "webp") { url, alt, width, height }
        }
        project_tags {
          id
          title
        }
      }
    }
    courses {
      ... on Entry_Courses_Course {
        id
        url
        title
        image {
          id
          sm: responsiveAsset(width: 400, height: 240, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 600, height: 360, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 800, height: 480, format: "webp") { url, alt, width, height }
        }
        course_dates {
          id
          course_dates_date
        }
      }
    }
    reviews {
      ... on Entry_Reviews_Review {
        id
        quote
        author
        company
        image {
          id
          sm: responsiveAsset(width: 400, height: 240, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 600, height: 360, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 800, height: 480, format: "webp") { url, alt, width, height }
        }
      }
    }
    services {
      ... on Entry_Services_Service {
        id
        url
        title
        image {
          id
          sm: responsiveAsset(width: 400, height: 240, format: "webp") { url, alt, width, height }
          md: responsiveAsset(width: 600, height: 360, format: "webp") { url, alt, width, height }
          lg: responsiveAsset(width: 800, height: 480, format: "webp") { url, alt, width, height }
        }
      }
    }
  }

  fragment Footer on Set_Blocks_Footer {
    ${defaultBlockFields}
    variant {
      ... on Entry_Footers_Footer {
        ${buttonFields}
        id
        title_color { value }
        title
        text
      }
    }
  }

  fragment TitleText on Set_Blocks_TitleText {
    ${defaultBlockFields}
    title_color { value }
    title
    text
  }

  fragment TextImage on Set_Blocks_TextImage {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
    text
    image {
      id
      md: responsiveAsset(width: 450, height: 440, format: "webp") { url, alt, width, height }
      lg: responsiveAsset(width: 900, height: 880, format: "webp") { url, alt, width, height }
    }
    image_left
  }

  fragment Awards on Set_Blocks_Awards {
    ${defaultBlockFields}
    title_color { value }
    title
    text
    images {
      id
      xs: responsiveAsset(height: 140) { url, alt, width, height }
    }
  }

  fragment Statistics on Set_Blocks_Statistics {
    ${defaultBlockFields}
    statistics {
      id
      statistic_text
      statistic_value
      statistic_prepend
      statistic_append
    }
  }

  fragment Employees on Set_Blocks_Employees {
    ${defaultBlockFields}
    title_color { value }
    title
    text
  }

  fragment CallToAction on Set_Blocks_CallToAction {
    ${defaultBlockFields}
    call_to_action {
      ... on Entry_CallToActions_CallToAction {
        ${buttonFields}
        title
        text
        whatsapp_number
        phone_number
        email
        employees {
          ... on Entry_Employees_Employee {
            title
            email
            position
            image {
              id
              sm: responsiveAsset(width: 312, height: 312, format: "webp") { url, alt, width, height }
            }
          }
        }
      }
    }
  }

  fragment ContactForm on Set_Blocks_ContactForm {
    ${defaultBlockFields}
    title_color { value }
    title
    entry_after_submit {
      ... on Entry_Pages_Page {
        url
      }
    }
  }

  fragment Projects on Set_Blocks_Projects {
    ${defaultBlockFields}
    title_color { value }
    title
  }

  fragment Articles on Set_Blocks_Articles {
    ${defaultBlockFields}
    title_color { value }
    title
  }

  fragment Courses on Set_Blocks_Courses {
    ${defaultBlockFields}
    title_color { value }
    title
    text
  }

  fragment Vacancies on Set_Blocks_Vacancies {
    ${defaultBlockFields}
    title
  }

  fragment Text on Set_Blocks_Text {
    ${defaultBlockFields}
    ${buttonFields}
    centered
    text
  }

  fragment Image on Set_Blocks_Image {
    ${defaultBlockFields}
    image {
      id
      sm: responsiveAsset(width: 400, format: "webp") { url, alt, width, height }
      md: responsiveAsset(width: 800, format: "webp") { url, alt, width, height }
      lg: responsiveAsset(width: 1200, format: "webp") { url, alt, width, height }
      xl: responsiveAsset(width: 1600, format: "webp") { url, alt, width, height }
    }
  }

  fragment TwoImageColumns on Set_Blocks_TwoImageColumns {
    ${defaultBlockFields}
    two_columns {
      id
      column_title
      column_text
      column_image {
        id
        sm: responsiveAsset(width: 440, height: 420, format: "webp") { url, alt, width, height }
        md: responsiveAsset(width: 640, height: 620, format: "webp") { url, alt, width, height }
        lg: responsiveAsset(width: 840, height: 820, format: "webp") { url, alt, width, height }
      }
    }
  }

  fragment ThreeImageColumns on Set_Blocks_ThreeImageColumns {
    ${defaultBlockFields}
    is_large
    three_columns {
      id
      column_image {
        id
        sm: responsiveAsset(width: 220, height: 240, format: "webp") { url, alt, width, height }
        md: responsiveAsset(width: 440, height: 470, format: "webp") { url, alt, width, height }
        lg: responsiveAsset(width: 880, height: 920, format: "webp") { url, alt, width, height }
      }
    }
  }

  fragment ThreeVideoColumns on Set_Blocks_ThreeVideoColumns {
    ${defaultBlockFields}
    three_video_columns {
      id
      poster {
        sm: responsiveAsset(width: 220, height: 240, format: "webp") { url, alt, width, height }
        md: responsiveAsset(width: 440, height: 470, format: "webp") { url, alt, width, height }
      }
      vimeo_url_sd_540p
    }
  }

  fragment SocialShare on Set_Blocks_SocialShare {
    ${defaultBlockFields}
  }

  fragment TwoTextColumns on Set_Blocks_TwoTextColumns {
    ${defaultBlockFields}
    two_text_columns {
      id
      title
      text
      ${buttonFields}
    }
  }

  fragment TitleTextImage on Set_Blocks_TitleTextImage {
    ${defaultBlockFields}
    title_color { value }
    title
    text
    image {
      id
      sm: responsiveAsset(width: 480, height: 432, format: "webp") { url, alt, width, height }
      md: responsiveAsset(width: 780, height: 700, format: "webp") { url, alt, width, height }
    }
  }

  fragment Services on Set_Blocks_Services {
    ${defaultBlockFields}
    title
    show_all_services
    pages {
      ... on Entry_Pages_Page {
        id
        url
        title
      }
    }
  }

  fragment TitleList on Set_Blocks_TitleList {
    ${defaultBlockFields}
    title_color { value }
    title
    list {
      id
      list_item_title
      list_item_text
    }
  }

  fragment Roadmap on Set_Blocks_Roadmap {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
    text
    image {
      id
      sm: responsiveAsset(width: 640, height: 430, format: "webp") { url, alt, width, height }
    }
    roadmap {
      roadmap_item_title
      roadmap_item_text
      roadmap_item_image {
        id
        sm: responsiveAsset(width: 640, height: 430, format: "webp") { url, alt, width, height }
      }
      roadmap_item_buttons {
        external
        button_text
        button_link
        button_variant { value }
      }
    }
    image_left
  }

  fragment CourseSignupForm on Set_Blocks_CourseSignupForm {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
    entry_after_submit {
      ... on Entry_Pages_Page {
        url
      }
    }
  }

  fragment VacancyApplyForm on Set_Blocks_VacancyApplyForm {
    ${defaultBlockFields}
    entry_after_submit {
      ... on Entry_Pages_Page {
        url
      }
    }
    call_to_action {
      ... on Entry_CallToActions_CallToAction {
        text
        whatsapp_number
        phone_number
        email
        employees {
          ... on Entry_Employees_Employee {
            title
            email
            position
            image {
              id
              sm: responsiveAsset(width: 312, height: 312, format: "webp") { url, alt, width, height }
            }
          }
        }
      }
    }
  }

  fragment ProductRequestForm on Set_Blocks_ProductRequestForm {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
    product_title
    text_after_submit
    entry_after_submit {
      ... on Entry_Pages_Page {
        url
      }
    }
    call_to_action {
      ... on Entry_CallToActions_CallToAction {
        text
        whatsapp_number
        phone_number
        email
        employees {
          ... on Entry_Employees_Employee {
            title
            email
            position
            image {
              id
              sm: responsiveAsset(width: 312, height: 312, format: "webp") { url, alt, width, height }
            }
          }
        }
      }
    }
  }

  fragment TitleFourUspsColumns on Set_Blocks_TitleFourUspsColumns {
    ${defaultBlockFields}
    title_centered
    title_color { value }
    title
    four_usps_columns {
      id
      column_text
      column_text_on_hover
      column_image {
        id
        sm: responsiveAsset(width: 300, height: 330, format: "webp") { url, alt, width, height }
      }
    }
  }

  fragment TitleTextFourUspsColumns on Set_Blocks_TitleTextFourUspsColumns {
    ${defaultBlockFields}
    title_color { value }
    title
    text
    title_text_four_usps_columns {
      id
      column_text
      column_text_on_hover
      column_image {
        id
        sm: responsiveAsset(width: 400, height: 440, format: "webp") { url, alt, width, height }
        md: responsiveAsset(width: 600, height: 660, format: "webp") { url, alt, width, height }
      }
    }
  }

  fragment TitleTextThreeColumns on Set_Blocks_TitleTextThreeColumns {
    ${defaultBlockFields}
    title
    text
    title_text_three_columns {
      id
      column_title
      column_text
      column_image {
        id
        sm: responsiveAsset(width: 320, height: 256, format: "webp") { url, alt, width, height }
        md: responsiveAsset(width: 640, height: 512, format: "webp") { url, alt, width, height }
      }
      column_buttons {
        external
        button_text
        button_link
        button_variant { value }
      }
    }
  }

  fragment TitleButton on Set_Blocks_TitleButton {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
  }

  fragment TextEmployees on Set_Blocks_TextEmployees {
    ${defaultBlockFields}
    ${buttonFields}
    text
    employees {
      ... on Entry_Employees_Employee {
        id
        url
        title
        position
        image {
          id
          sm: responsiveAsset(width: 312, height: 312, format: "webp") { url, alt, width, height }
        }
      }
    }
    title_above_employees
    buttons_below_employees {
      external
      button_text
      button_link
      button_variant { value }
    }
  }

  fragment Planning on Set_Blocks_Planning {
    ${defaultBlockFields}
    title_color { value }
    title
    text
    image {
      id
      md: responsiveAsset(width: 540, format: "webp") { url, alt, width, height }
      lg: responsiveAsset(width: 960, format: "webp") { url, alt, width, height }
      xl: responsiveAsset(width: 1300, format: "webp") { url, alt, width, height }
    }
  }

  fragment Reviews on Set_Blocks_Reviews {
    ${defaultBlockFields}
    title_color { value }
    title
    reviews {
      ... on Entry_Reviews_Review {
        id
        quote
        author
        company
      }
    }
  }

  fragment TitleLabels on Set_Blocks_TitleLabels {
    ${defaultBlockFields}
    title
    labels
  }

  fragment Links on Set_Blocks_Links {
    ${defaultBlockFields}
    ${buttonFields}
    title_color { value }
    title
  }
`;

export const blocksQuery = `
  blocks {
    ...Hero
    ...HeroPage
    ...HeroProject
    ...HeroArticle
    ...HeroService
    ...HeroCourse
    ...HeroVacancy
    ...HeroEmployee
    ...HeroLandingPage
    ...TextImages
    ...Accordion
    ...Video
    ...Image
    ...TitleEntries
    ...Footer
    ...TitleText
    ...TextImage
    ...Awards
    ...Statistics
    ...Employees
    ...CallToAction
    ...ContactForm
    ...Projects
    ...Articles
    ...Courses
    ...Vacancies
    ...Text
    ...TwoImageColumns
    ...ThreeImageColumns
    ...ThreeVideoColumns
    ...SocialShare
    ...TwoTextColumns
    ...TitleTextImage
    ...Services
    ...TitleList
    ...Roadmap
    ...CourseSignupForm
    ...TitleFourUspsColumns
    ...TitleTextFourUspsColumns
    ...TitleTextThreeColumns
    ...TitleButton
    ...TextEmployees
    ...Planning
    ...Reviews
    ...VacancyApplyForm
    ...ProductRequestForm
    ...TitleLabels
    ...Links
  }
`;
