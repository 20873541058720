const canvasState = reactive<{
  vec3?: string;
  backgroundColor?: string;
}>({
  vec3: '0.387, 0.559, 0.845',
  backgroundColor: '#010101',
});

const backgroundColorVec3: {[key: string]: string} = {
  'bg-white': '',
  'bg-blue-light': '0.02, 0.078, 0.027',
  'bg-transparent': '0.387, 0.559, 0.845',
  'bg-purple-light': '0.380, 0.400, 0.368',
};

export const useCanvas = () => {
  function changeCanvasColor(color: string = 'bg-transparent') {
    canvasState.vec3 = backgroundColorVec3[color];
    canvasState.backgroundColor = color;
  }

  return {
    canvasState,
    changeCanvasColor,
  };
};
