export const seoQuery = `
  seo_title
  seo_description
  seo_noindex
  seo_nofollow
  og_title
  og_description
  og_image {
    responsiveAsset(width: 1200, format: "jpg") { url, width, height }
  }
`;
